import * as React from 'react';
import styles from './ActiveDoll.scss';
import classnames from 'classnames';
import { isAuthorizedUser } from '~/config/user';
import { useSelector, shallowEqual } from 'react-redux';
import { State } from '~/Reducers';
import { IActiveDogTag } from '~/interfaces';
import DogTagClanTag from '~/Components/ActiveDoll/DogTagСlanTag/DogTagClanTag';

interface IState {
    activeDogTag: IActiveDogTag;
    clanTag?: string;
}

const stateSelector = (state: State): IState => {
    return {
        activeDogTag: state.ReducerApp.inventory?.activeDogTag,
        clanTag: state.ReducerApp.clanData?.clan?.tag,
    };
};

const ActiveDoll = () => {
    const { activeDogTag, clanTag } = useSelector<State, IState>(stateSelector, shallowEqual);

    if (!isAuthorizedUser() || !activeDogTag?.symbol?.icons?.medium) {
        return null;
    }

    const background = activeDogTag.background;
    const symbol = activeDogTag.symbol;
    const isColorizable = activeDogTag.isColorizable || !!activeDogTag.texture?.backgroundIcons?.medium;
    const symbolImage = symbol.icons.medium;
    const backgroundImage = isColorizable ? activeDogTag.texture.backgroundIcons.medium : background?.icons?.medium;
    const borderImage = isColorizable ? activeDogTag.texture.borderIcons?.medium : '';
    const borderColor = (isColorizable && activeDogTag.borderColor?.replace('0x', '#')) || 'none';
    const backgroundColor = (isColorizable && activeDogTag.backgroundColor?.replace('0x', '#')) || 'none';


    const symbolOffsetY = background?.symbolOffsetY ? background.symbolOffsetY * 100 : '';

    const clanTagData = activeDogTag.clanTag;
    const showClanTag = activeDogTag.showClanTag;
    const clanTagColor = clanTagData?.fontColor || borderColor || '#FFFFFF';
    const clanTagX = clanTagData?.x;
    const clanTagY = clanTagData?.y;

    return (
        <div className={styles.activeDogTag}>
            {backgroundImage && (
                <div
                    className={classnames(styles.background, styles.component, isColorizable && styles.colorizable)}
                    style={
                        {
                            '--image': `url('${backgroundImage}')`,
                            backgroundColor: backgroundColor,
                        } as React.CSSProperties
                    }
                >
                    <img src={backgroundImage} />
                </div>
            )}
            {borderImage && (
                <div
                    className={classnames(styles.border, styles.component, isColorizable && styles.colorizable)}
                    style={
                        {
                            '--image': `url('${borderImage}')`,
                            backgroundColor: borderColor,
                        } as React.CSSProperties
                    }
                >
                    <img src={borderImage} />
                </div>
            )}
            {symbolImage && (
                <div
                    className={classnames(styles.symbol, styles.component)}
                    style={{
                        top: `${symbolOffsetY}%`,
                    }}
                >
                    <img src={symbolImage} />
                </div>
            )}
            {showClanTag && (
                <DogTagClanTag className={styles.clanTag} tag={clanTag} color={clanTagColor} x={clanTagX} y={clanTagY} />
            )}
        </div>
    );
};

export default ActiveDoll;
