import { generatePath } from 'react-router-dom';
import { navigate } from '~/helpers/consts';
import { pushToHistory } from '~/helpers/router';

type EntityId = string | number;

export function generatePortUrl(shipId: EntityId, exteriorId?: EntityId): string {
  return generatePath(navigate.PORT, { shipId: String(shipId) }) + (exteriorId ? `?exteriorId=${exteriorId}` : '');
}

export function openPort(shipId: EntityId, exteriorId?: EntityId) {
    const url = generatePortUrl(shipId, exteriorId);
    pushToHistory(url);
    window?.tooltipProvider?.hide?.();
}
