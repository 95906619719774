import * as React from 'react';
import { KEY_CODE } from '~/helpers/consts';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { hideDialog } from '~/Actions/ActionCategory';
import { loadAppData } from '~/Actions/ActionApp';
import { State } from '~/Reducers';
import Processing from '@wg/wows-react-uikit/Processing';
import ContainerView from '~/Containers/ContainerView';
import { default as globalStyles } from '../styles/global.scss';
import DialogContainer from '@wg/wows-react-uikit/DialogContainer';
import DialogHeader from '@wg/wows-react-uikit/DialogHeader';
import Button from '@wg/wows-react-uikit/Button';
import DialogFooter from '@wg/wows-react-uikit/DialogFooter';
import { t } from '~/helpers/localization';
import { IResponse } from '~/interfaces';

interface IState {
    response: IResponse;
    promoWidget: any;
    isErrorLoad: boolean;
}

const stateSelector = (state: State): IState => {
    return {
        response: state.ReducerApp.response,
        promoWidget: state.ReducerApp.promoWidget,
        isErrorLoad: state.ReducerApp.isErrorLoad,
    };
};

const ContainerApp = () => {
    const dispatch = useDispatch();
    const appState = useSelector<State, IState>(stateSelector, shallowEqual);

    React.useEffect(() => {
        dispatch(loadAppData());

        document.addEventListener('keydown', (event) => {
            if (event.keyCode === KEY_CODE.ESC) {
                dispatch(hideDialog());
            }
        });
    }, []);

    if (appState.isErrorLoad) {
        return (
            <div className={globalStyles.dialogWrap}>
                <DialogContainer>
                    <DialogHeader noUnderline={true}>{t('Произошла ошибка, пожалуйста, повторите попытку позже')}</DialogHeader>
                    <DialogFooter>
                        <Button onClick={() => dispatch(loadAppData())}>{t('Обновить')}</Button>
                    </DialogFooter>
                </DialogContainer>
            </div>
        );
    } else if (!appState.response) {
        return <Processing isFetching={true} />;
    }

    return <ContainerView isNeedToShowWelcomePage={false} />;
};

export default ContainerApp;
