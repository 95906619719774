import * as React from 'react';
import styles from './dialog.scss';
import { ICategories, IDialogData, IResponse, VortexItem } from '~/interfaces';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { DOLLS_CATEGORY, hideDialog, VEHICLE_CATEGORY } from '~/Actions/ActionCategory';
import { t } from '~/helpers/localization';
import { State } from '~/Reducers';
import { DOLLS_TYPES } from '~/helpers/consts';
import { Doll, Maybe, Scalars, Vehicle } from '~/vortex';
import DialogContainer from '@wg/wows-react-uikit/DialogContainer';
import DialogHeader from '@wg/wows-react-uikit/DialogHeader';
import DialogBody from '@wg/wows-react-uikit/DialogBody';
import DialogFooter from '@wg/wows-react-uikit/DialogFooter';
import Button from '@wg/wows-react-uikit/Button';
import { Scrollbars } from 'react-custom-scrollbars';
import { renderThumb } from '~/Layouts/Category/CategoryLayout';
import classNames from 'classnames';
import { getVehicleTypeIcon } from '~/helpers/vehiclesApi';
import { getLevelFromRomanFormat } from '~/helpers/levels';
import { playButtonClickSound } from '~/helpers/audioApi';
import { getLocalPath, isInGameBrowser } from '~/helpers/utils';
import DogTagBackground from '~/Components/DogTagBackground/DogTagBackground';

interface IDialogItemInfo {
    data: IDialogData;
}

type DIALOG_ITEM = VortexItem & {
    description: string;
    icons: {
        large?: Maybe<Scalars['MediaUrlField']>;
        small?: Maybe<Scalars['MediaUrlField']>;
        default?: Maybe<Scalars['MediaUrlField']>;
    };
};

interface IDialogBodyContent {
    category: ICategories;
    item: DIALOG_ITEM;
}

const DialogBodyContent = (props: IDialogBodyContent) => {
    const description = props.item.description;
    const style = { backgroundImage: `url(${props.item.icons.large})` };
    const dialogTitleClassNames = classNames(styles.dialogDescriptionTitle, {
        [styles.center]: props.category === DOLLS_CATEGORY,
    });

    const dialogImageContent = () => {
        switch (props.category) {
            case VEHICLE_CATEGORY:
                const ship = props.item as Vehicle;
                const background = { backgroundImage: `url(${ship.nation.icons.large})` };

                return (
                    <React.Fragment>
                        <div className={styles.fogMask}>
                            <div className={styles.fog1} />
                            <div className={styles.fog2} />
                        </div>
                        <div className={styles.dialogBackgroundImage} style={background} />
                        <div className={styles.dialogImage} style={style} />
                        <div className={styles.dialogImageWater} />
                    </React.Fragment>
                );

            case DOLLS_CATEGORY:
                if ((props.item as Doll)?.type === DOLLS_TYPES.PATCH) {
                    return <div className={styles.dialogImage} style={style} />;
                }
                return (
                    <div className={styles.dialogImage}>
                        <DogTagBackground doll={props.item as Doll} className={styles.dogTagBg} size="extraLarge" />
                    </div>
                );

            default:
                return <div className={styles.dialogImage} style={style} />;
        }
    };

    const dialogTitle = () => {
        switch (props.category) {
            case VEHICLE_CATEGORY:
                const ship = props.item as Vehicle;
                const iconStyle = { backgroundImage: `url(${getVehicleTypeIcon(ship)})` };
                const classNameDescriptionName = classNames(styles.dialogDescriptionName, styles.uppercase, {
                    [styles.gold]: ship.isPremium || ship.isSpecial,
                });

                return (
                    <div className={styles.dialogDescriptionTitle}>
                        <div className={styles.dialogDescriptionTypeIcon} style={iconStyle} />
                        <div className={classNameDescriptionName}>
                            {getLevelFromRomanFormat(ship.level)} {ship.title}
                        </div>
                    </div>
                );

            default:
                return <div className={styles.dialogDescriptionName}>{props.item.title}</div>;
        }
    };

    return (
        <div className={styles.dialogWrap}>
            <div className={styles.dialogImageWrap}>{dialogImageContent()}</div>
            <div className={styles.dialogDescription}>
                <div className={dialogTitleClassNames}>{dialogTitle()}</div>
                {description && (
                    <Scrollbars renderThumbVertical={renderThumb} style={{ height: '100px' }} autoHide={true}>
                        <div className={styles.descriptionText}>{description}</div>
                    </Scrollbars>
                )}
            </div>
        </div>
    );
};

interface IState {
    response: IResponse;
}

const stateSelector = (state: State): IState => {
    return {
        response: state.ReducerApp.response,
    };
};

const DialogItemInfo = (props: IDialogItemInfo) => {
    const dispatch = useDispatch();
    const closeDialog = () => {
        playButtonClickSound();
        dispatch(hideDialog());
    };
    const appState = useSelector<State, IState>(stateSelector, shallowEqual);

    const items = appState.response[props.data.popupData.category] as VortexItem[];
    const itemData: VortexItem = items.filter((item: VortexItem) => item.id.toString() === props.data.popupData.itemId.toString())[0];

    const getTitle = () => {
        if (!itemData) {
            return null;
        }

        if (props.data.popupData.category === DOLLS_CATEGORY) {
            const dollItem = itemData as Doll;
            return dollItem.type === DOLLS_TYPES.PATCH ? (dollItem.isPatch ? t('Нашивка') : t('Символ')) : t('Форма');
        } else if (props.data.popupData.category === VEHICLE_CATEGORY) {
            const shipItem = itemData as Vehicle;
            return shipItem.type.title;
        }

        return itemData.title;
    };

    const item = itemData as DIALOG_ITEM;

    return (
        <DialogContainer>
            <DialogHeader>
                <div>{getTitle()}</div>
                <div className={styles.close} onClick={closeDialog}>
                    {t('Закрыть')}
                </div>
            </DialogHeader>
            <div>
                <DialogBody>
                    <DialogBodyContent item={item} category={props.data.popupData.category} />
                </DialogBody>
            </div>
            <DialogFooter>
                <Button onClick={closeDialog}>{t('Закрыть')}</Button>
            </DialogFooter>
        </DialogContainer>
    );
};

export default DialogItemInfo;
