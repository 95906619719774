import * as React from 'react';
import { _Item, ICategories, IInventoryList, IInventoryState, IResponse, Item } from '~/interfaces';
import { shallowEqual, useSelector } from 'react-redux';
import { State } from '~/Reducers';
import { INVENTORY_KEY_BY_CATEGORY } from '~/helpers/consts';
import ProgressCollected from '~/Components/Profile/ProgressCollected';
import CategoryLayout from '~/Layouts/Category/CategoryLayout';

interface IContainerCategory {
    category: ICategories;
}

interface IState {
    inventory: IInventoryState;
    response: IResponse;
    searchResults: any;
    filteredItems: _Item[];
}

const stateSelector = (state: State): IState => {
    return {
        inventory: state.ReducerApp.inventory,
        response: state.ReducerApp.response,
        searchResults: state.ReducerCategory.search,
        filteredItems: state.ReducerFilter.filteredItems,
    };
};

const ContainerCategory = (props: IContainerCategory) => {
    const appState = useSelector<State, IState>(stateSelector, shallowEqual);
    const inventoryKey = INVENTORY_KEY_BY_CATEGORY[props.category] as IInventoryList;
    const inventoryItems = appState.inventory[inventoryKey];
    const progressCollected = new ProgressCollected(appState.response, appState.inventory);

    let filteredTotalCount: number | undefined;

    let items = appState.response[props.category];
    if (Array.isArray(appState.searchResults?.results)) {
        items = appState.searchResults?.results;
    } else if (appState.filteredItems) {
        items = appState.filteredItems;

        if (inventoryItems) {
            filteredTotalCount = appState.filteredItems.filter((item) => {
                return 'id' in item ? inventoryItems.indexOf(item['id'] as string) > -1 : false;
            }).length;
        }
    }

    const _props = {
        category: props.category,
        availableAwardsCount: progressCollected.getAvailableCountByKey(inventoryKey, props.category, props.category),
        totalCount: progressCollected.getTotalCountByKey(props.category, false),
        filteredTotalCount: filteredTotalCount,
        uniqueAwardsCount: progressCollected.getTotalUniqueCountByKey(props.category),
        rareAwardsCount: progressCollected.getTotalRareCountByKey(props.category),
        items: items as Item[],
        inventoryItems: inventoryItems,
    };

    return <CategoryLayout {..._props} key={props.category} />;
};

export default ContainerCategory;
