import { tpl } from '~/helpers/tpl';

export const query = tpl`
{
    permoflages (lang: "{{ languageCode }}", isCatalogue: true) {
        title
        id
        tags
        vehicles {
            vehicle {
                id
                title
                level
                tags
                nation {
                    name
                }
                type {
                    name
                }
            }
        }
    }
}
`;
