import React from 'react';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import styles from './PromoWidget.scss';
import { t } from '~/helpers/localization';
import ButtonPromo from '@wg/wows-react-uikit/ButtonPromo';
import { promoTexts } from './promoTexts';
import { changePromoSlide } from '~/Actions/ActionApp';
import { State } from '~/Reducers';
import { playButtonClickSound } from '~/helpers/audioApi';

interface IProps {
    type: string;
    onFinish?: () => void;
    isRenderCloseButton?: boolean;
}

interface IState {
    slide: number;
}

const stateSelector = (state: State): IState => {
    return {
        slide: state.ReducerApp.promoWidget?.slide || 0,
    };
};

const PromoWidget = (props: IProps) => {
    const dispatch = useDispatch();
    const texts = promoTexts[props.type] || [];
    const appState = useSelector<State, IState>(stateSelector, shallowEqual);
    const promoData = texts[appState.slide];

    const onNextSlide = () => {
        dispatch(changePromoSlide(appState.slide + 1, false));
    };

    const onPrevSlide = () => {
        playButtonClickSound();
        dispatch(changePromoSlide(Math.max(appState.slide - 1, 0), false));
    };

    const onFinish = () => {
        dispatch(changePromoSlide(null, true));
        props.onFinish?.();
    };

    const { title, description } = promoData;

    return (
        <div className={styles.promoWidget}>
            <div className={styles.promoWidgetTitle}>
                <span>{title}</span>
                {props.isRenderCloseButton && (
                    <div
                        className={styles.promoWidgetClose}
                        onClick={() => {
                            playButtonClickSound();
                            onFinish();
                        }}
                    >
                        {t('Закрыть')}
                    </div>
                )}
            </div>
            <div className={styles.promoWidgetText}>{description}</div>
            <div className={styles.promoWidgetFooter}>
                {appState.slide !== 0 && (
                    <div onClick={onPrevSlide}>
                        <button className={styles.prevButton}>{t('Назад')}</button>
                    </div>
                )}
                {appState.slide + 1 !== texts.length && (
                    <ButtonPromo onClick={onNextSlide} isCenter={true}>
                        {t('Далее')}
                    </ButtonPromo>
                )}
                {appState.slide + 1 === texts.length && (
                    <ButtonPromo onClick={onFinish} isCenter={true}>
                        {t('Мне все понятно')}
                    </ButtonPromo>
                )}
            </div>
        </div>
    );
};

export default PromoWidget;
