import { tpl } from '~/helpers/tpl';

export const query = tpl`
{
    dogTagComponents(lang: "{{ languageCode }}", isCatalogue: true) {
        id
        title
        tags
        type
        isPatch
    }
}
`;
