import * as React from 'react';
import { t } from '~/helpers/localization';
import { CREWS_CATEGORY, DOLLS_CATEGORY, ENSIGNS_CATEGORY, PERMOFLAGES_CATEGORY, VEHICLE_CATEGORY } from '~/Actions/ActionCategory';
import { categoriesSettings, isInGameBrowser, settings } from '~/helpers/utils';
import { ICategories } from '~/interfaces';
import { goToWebProject, PROJECTS } from '@wg/web2clientapi/browser/goToWebProject';

export const preTitles = {
    [VEHICLE_CATEGORY]: t('Пополнить свою коллекцию кораблей можно в {{ link_open_1 }}Адмиралтействе{{ link_close_1 }} и {{ link_open_2 }}Премиум магазине{{ link_close_2 }}'),
    [ENSIGNS_CATEGORY]: t('Памятные флаги доступны в наборах с кораблями из {{ link_open }}Премиум магазина{{ link_close }}, а также за победы и достижения в игровых активностях.'),
    [CREWS_CATEGORY]: t('Больше легендарных командиров можно найти в {{ link_open }}Адмиралтействе{{ link_close }}, а также получить в награду за сбор коллекции или победы в игровых активностях.'),
    [DOLLS_CATEGORY]: t('Знаки различия можно найти в {{ link_open }}Адмиралтействе{{ link_close }}. Они также доступны в качестве награды, которую можно получить из ежедневных контейнеров.'),
    [PERMOFLAGES_CATEGORY]: t(
        'Некоторые постоянные камуфляжи можно найти во вкладке «Внешний вид» соответствующего корабля или во время игровых событий в {{ link_open }}Адмиралтействе{{ link_close }}.',
    ),
};

export const titlesParams: {
    [key: string]: {
        armoryUrl?: string[];
        premiumShopUrl?: string[];
    };
} = {
    [VEHICLE_CATEGORY]: {
        armoryUrl: ['link_open_1', 'link_close_1'],
        premiumShopUrl: ['link_open_2', 'link_close_2'],
    },
    [ENSIGNS_CATEGORY]: {
        premiumShopUrl: ['link_open', 'link_close'],
    },
    [CREWS_CATEGORY]: {
        armoryUrl: ['link_open', 'link_close'],
    },
    [DOLLS_CATEGORY]: {
        armoryUrl: ['link_open', 'link_close'],
    },
    [PERMOFLAGES_CATEGORY]: {
        armoryUrl: ['link_open', 'link_close'],
    },
};

export const parseCategoryTitle = (category: ICategories) => {
    let str = preTitles[category];
    const keys = Object.keys(titlesParams[category]);

    if (!categoriesSettings[category]?.isCategoryTitleBlockShown) {
        return null;
    }

    keys.forEach((key: 'armoryUrl' | 'premiumShopUrl') => {
        const tags = titlesParams[category][key];
        const url = categoriesSettings[category][key];
        if (url) {
            str = str.split(`{{ ${tags[0]} }}`).join(`<a href='${url}' target='_blank'>`).split(`{{ ${tags[1]} }}`).join('</a>');
        } else {
            str = str.split(`{{ ${tags[0]} }}`).join('').split(`{{ ${tags[1]} }}`).join('');
        }
    });

    return str;
};

export const onClickByHeaderTitle = (event: React.MouseEvent, category: ICategories) => {
    const isAvailableArmoryByUrl = !!titlesParams[category].armoryUrl;
    if (!isAvailableArmoryByUrl) {
        return;
    }

    const target = event.target as HTMLLinkElement;
    const url = new URL(target.href);

    if (target.tagName.toLowerCase() === 'a') {
        if (target.href === settings.categories[category].armoryUrl) {
            if (isInGameBrowser) {
                event.preventDefault();
                goToWebProject(PROJECTS.metaShop, url.pathname);
            }
        } else if (target.href === settings.categories[category].premiumShopUrl) {
            if (isInGameBrowser) {
                event.preventDefault();
                goToWebProject(PROJECTS.shop, url.hash);
            }
        }
    }
};
