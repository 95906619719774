import * as React from 'react';
import { FILTERS_NAMES, IFilterValues } from '~/Components/Filter/filtersMap';
import classNames from 'classnames';
import styles from '~/Components/Filter/Filter.scss';
import DivTooltip from '@wg/wows-react-uikit/DivTooltip';
import DefaultTooltip from '~/Components/Tooltip/DefaultTooltip';
import CheckboxWithLabel from '@wg/wows-react-uikit/CheckboxWithLabel';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { updateCategoryFilters } from '~/Actions/ActionFilter';
import { ICategories } from '~/interfaces';
import { State } from '~/Reducers';
import { IFilters } from '~/Reducers/ReducerFilter';
import { getFiltersStateByCategory } from '~/settings/filters';

interface IState {
    filters: IFilters;
    facetFilters: IFilters;
}

interface IProps {
    name: FILTERS_NAMES;
    values: IFilterValues[];
    category: ICategories;
}

const stateSelector = (state: State): IState => {
    return {
        filters: state.ReducerFilter.filters,
        facetFilters: state.ReducerFilter.facetFilters,
    };
};

export const NationsFilters = ({ name, values, category }: IProps) => {
    const dispatch = useDispatch();
    const appState = useSelector<State, IState>(stateSelector, shallowEqual);
    const filters = getFiltersStateByCategory(appState.filters, category);
    const facetFilters = getFiltersStateByCategory(appState.facetFilters, category);
    const selectedFilters = filters[category][name];

    const nationsList = values.reduce((state, nation) => {
        const style = { backgroundImage: `url(${nation.icons.tiny})` };
        const isChecked = selectedFilters.includes(nation.value.toString());
        const isDisabled = facetFilters[category]?.[name].includes(nation.value);

        const className = classNames(styles.filterNation, {
            [styles.checkboxDisabled]: isDisabled,
        });

        const withTooltip = (
            <DivTooltip tooltipBody={<DefaultTooltip text={nation.title.toString()} />}>
                <span className={className} style={style} />
            </DivTooltip>
        );

        state.push(
            <div key={nation.title} className={styles.filterCheckboxItem}>
                <CheckboxWithLabel
                    onChange={() => {
                        dispatch(updateCategoryFilters(category, name, nation.value.toString()));
                    }}
                    isChecked={isChecked}
                    isDisabled={isDisabled}
                    labelText={withTooltip}
                />
            </div>,
        );

        return state;
    }, []);

    const nationsColumns = [];
    for (let i = 0; i < nationsList.length; i += 6) {
        const array = nationsList.slice(i, i + 6);
        nationsColumns.push(
            <div className={styles.columns} key={i}>
                {array}
            </div>,
        );
    }

    return <React.Fragment>{nationsColumns}</React.Fragment>;
};

export const FilterShipTypes = ({ name, values, category }: IProps) => {
    const dispatch = useDispatch();
    const appState = useSelector<State, IState>(stateSelector, shallowEqual);
    const filters = getFiltersStateByCategory(appState.filters, category);
    const facetFilters = getFiltersStateByCategory(appState.facetFilters, category);
    const selectedFilters = filters[category][name];

    return (
        <React.Fragment>
            {values.map((type) => {
                const isChecked = selectedFilters.includes(type.value);
                const isDisabled = facetFilters[category]?.[name].includes(type.value);

                const className = classNames(styles.filterTypeIcon, {
                    [styles.checkboxDisabled]: isDisabled,
                });

                const style = {
                    backgroundImage: `url(${type.icons.default})`,
                };

                const withTooltip = (
                    <DivTooltip tooltipBody={<DefaultTooltip text={type.title.toString()} />}>
                        <span className={className} style={style} />
                    </DivTooltip>
                );

                return (
                    <div className={styles.filterCheckboxItem} key={type.value}>
                        <CheckboxWithLabel
                            isChecked={isChecked}
                            isDisabled={isDisabled}
                            onChange={() => {
                                dispatch(updateCategoryFilters(category, name, type.value.toString()));
                            }}
                            labelText={withTooltip}
                        />
                    </div>
                );
            })}
        </React.Fragment>
    );
};

export const FilterLevels = ({ name, values, category }: IProps) => {
    const dispatch = useDispatch();
    const appState = useSelector<State, IState>(stateSelector, shallowEqual);
    const filters = getFiltersStateByCategory(appState.filters, category);
    const facetFilters = getFiltersStateByCategory(appState.facetFilters, category);
    const selectedFilters = filters[category][name];

    const levelsItems = values.reduce((state, level) => {
        const isChecked = selectedFilters.includes(level.value);
        const isDisabled = facetFilters[category]?.[name].includes(level.value);
        const className = classNames(styles.label, {
            [styles.checkboxDisabled]: isDisabled,
        });

        state.push(
            <div className={styles.filterCheckboxItem} key={level.value}>
                <CheckboxWithLabel
                    isChecked={isChecked}
                    isDisabled={isDisabled}
                    onChange={() => {
                        dispatch(updateCategoryFilters(category, name, level.value.toString()));
                    }}
                    labelText={<span className={className}>{level.title}</span>}
                />
            </div>,
        );

        return state;
    }, []);

    const columns = [];
    for (let i = 0; i < levelsItems.length; i += 5) {
        const array = levelsItems.slice(i, i + 5);
        columns.push(
            <div className={styles.columns} key={i}>
                {array}
            </div>,
        );
    }

    return <React.Fragment>{columns}</React.Fragment>;
};

export const DefaultFilter = ({ name, values, category }: IProps) => {
    const dispatch = useDispatch();
    const appState = useSelector<State, IState>(stateSelector, shallowEqual);
    const filters = getFiltersStateByCategory(appState.filters, category);
    const facetFilters = getFiltersStateByCategory(appState.facetFilters, category);
    const selectedFilters = filters[category][name];

    return (
        <React.Fragment>
            {values.map((value) => {
                const isChecked = selectedFilters.includes(value.value);
                const isDisabled = facetFilters[category]?.[name].includes(value.value);

                const className = classNames(styles.label, {
                    [styles.checkboxDisabled]: isDisabled,
                });

                return (
                    <div className={styles.filterCheckboxItem} key={value.value}>
                        <CheckboxWithLabel
                            isChecked={isChecked}
                            isDisabled={isDisabled}
                            onChange={() => {
                                dispatch(updateCategoryFilters(category, name, value.value.toString()));
                            }}
                            labelText={<span className={className}>{value.title}</span>}
                        />
                    </div>
                );
            })}
        </React.Fragment>
    );
};
