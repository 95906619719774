import { progressCollectedSteps } from '~/helpers/utils';
import { ICategories, IInventoryList, IInventoryState, IResponse, Item } from '~/interfaces';
import {
    CREWS_CATEGORY,
    DOLLS_CATEGORY,
    ENSIGNS_CATEGORY,
    INVENTORY_CREW_KEY,
    INVENTORY_DOGTAG_KEY,
    INVENTORY_HOLD_KEY,
    INVENTORY_STORAGE_KEY,
    INVENTORY_VEHICLES_KEY,
    PERMOFLAGES_CATEGORY,
    VEHICLE_CATEGORY,
} from '~/Actions/ActionCategory';

export interface IDevData {
    [key: string]: number;
}

export default class ProgressCollected {
    steps = progressCollectedSteps;

    private readonly devModeData: IDevData;

    private readonly inventory: IInventoryState;

    private readonly storage: IResponse;

    constructor(storage: IResponse, inventory: IInventoryState, devData?: any) {
        this.storage = storage;
        this.inventory = inventory;
        this.devModeData = devData || {
            [VEHICLE_CATEGORY]: null,
            [ENSIGNS_CATEGORY]: null,
            [CREWS_CATEGORY]: null,
            [DOLLS_CATEGORY]: null,
            [PERMOFLAGES_CATEGORY]: null,
        };
    }

    getTotalCountByKey(key: ICategories, isCountedUniqueTrophy: boolean) {
        const items = this.storage[key] as Item[];
        return items.reduce((count: number, item: Item) => {
            if (isCountedUniqueTrophy && (this.getTotalUniqueCountByKey(key) > 0 || this.getTotalRareCountByKey(key) > 0)) {
                count++;
            } else if (!item.isUniqueItem && !item.isRareItem) {
                count++;
            }

            return count;
        }, 0);
    }

    getTotalUniqueCountByKey(key: ICategories) {
        const items = this.storage[key] as Item[];
        return items.reduce((count: number, item: Item) => {
            if (item.isUniqueItem) {
                count++;
            }

            return count;
        }, 0);
    }

    getTotalRareCountByKey(key: ICategories) {
        const items = this.storage[key] as Item[];
        return items.reduce((count: number, item: Item) => {
            if (item.isRareItem) {
                count++;
            }

            return count;
        }, 0);
    }

    getAvailableCountByKey(inventoryKey: IInventoryList, glossaryKey: ICategories, category: ICategories, isCountedUniqueTrophy?: boolean) {
        const items = this.storage[glossaryKey] as Item[];
        return (
            this.devModeData[category] ??
            items.reduce((count: number, item: Item) => {
                if (isCountedUniqueTrophy && (this.getTotalUniqueCountByKey(glossaryKey) > 0 || this.getTotalRareCountByKey(glossaryKey) > 0) && this.inventory?.[inventoryKey]?.includes(item.id)) {
                    count++;
                } else if (!item.isRareItem && !item.isUniqueItem && this.inventory?.[inventoryKey]?.includes(item.id)) {
                    count++;
                }

                return count;
            }, 0)
        );
    }

    getTotalVehiclesCount(isCountedUniqueTrophy?: boolean) {
        return this.getTotalCountByKey(VEHICLE_CATEGORY, isCountedUniqueTrophy);
    }

    getTotalUniqueVehiclesCount() {
        return this.getTotalUniqueCountByKey(VEHICLE_CATEGORY);
    }

    getTotalRareVehiclesCount() {
        return this.getTotalRareCountByKey(VEHICLE_CATEGORY);
    }

    getAvailableVehiclesCount(isCountedUniqueTrophy?: boolean): number {
        return this.getAvailableCountByKey(INVENTORY_VEHICLES_KEY, VEHICLE_CATEGORY, VEHICLE_CATEGORY, isCountedUniqueTrophy);
    }

    getPercentProgressVehicle(isCountedUniqueTrophy?: boolean): number {
        return Math.floor((100 * this.getAvailableVehiclesCount(isCountedUniqueTrophy)) / this.getTotalVehiclesCount(isCountedUniqueTrophy));
    }

    getTotalCrewsCount(isCountedUniqueTrophy?: boolean): number {
        return this.getTotalCountByKey(CREWS_CATEGORY, isCountedUniqueTrophy);
    }

    getTotalUniqueCrewsCount(): number {
        return this.getTotalUniqueCountByKey(CREWS_CATEGORY);
    }

    getTotalRareCrewsCount(): number {
        return this.getTotalRareCountByKey(CREWS_CATEGORY);
    }

    getAvailableCrewCount(isCountedUniqueTrophy?: boolean): number {
        return this.getAvailableCountByKey(INVENTORY_CREW_KEY, CREWS_CATEGORY, CREWS_CATEGORY, isCountedUniqueTrophy);
    }

    getPercentProgressCrew(isCountedUniqueTrophy?: boolean): number {
        return Math.floor((100 * this.getAvailableCrewCount(isCountedUniqueTrophy)) / this.getTotalCrewsCount(isCountedUniqueTrophy));
    }

    getTotalPermoflagesCount(isCountedUniqueTrophy?: boolean): number {
        return this.getTotalCountByKey(PERMOFLAGES_CATEGORY, isCountedUniqueTrophy);
    }

    getTotalUniquePermoflagesCount(): number {
        return this.getTotalUniqueCountByKey(PERMOFLAGES_CATEGORY);
    }

    getTotalRarePermoflagesCount(): number {
        return this.getTotalRareCountByKey(PERMOFLAGES_CATEGORY);
    }

    getAvailablePermoflagesCount(isCountedUniqueTrophy?: boolean): number {
        return this.getAvailableCountByKey(INVENTORY_HOLD_KEY, PERMOFLAGES_CATEGORY, PERMOFLAGES_CATEGORY, isCountedUniqueTrophy);
    }

    getPercentProgressPermoflage(isCountedUniqueTrophy?: boolean): number {
        return Math.floor((100 * this.getAvailablePermoflagesCount(isCountedUniqueTrophy)) / this.getTotalPermoflagesCount(isCountedUniqueTrophy));
    }

    getTotalEnsignsCount(isCountedUniqueTrophy?: boolean): number {
        return this.getTotalCountByKey(ENSIGNS_CATEGORY, isCountedUniqueTrophy);
    }

    getTotalUniqueEnsignsCount(): number {
        return this.getTotalUniqueCountByKey(ENSIGNS_CATEGORY);
    }

    getTotalRareEnsignsCount(): number {
        return this.getTotalRareCountByKey(ENSIGNS_CATEGORY);
    }

    getAvailableEnsignsCount(isCountedUniqueTrophy?: boolean): number {
        return this.getAvailableCountByKey(INVENTORY_STORAGE_KEY, ENSIGNS_CATEGORY, ENSIGNS_CATEGORY, isCountedUniqueTrophy);
    }

    getPercentProgressEnsign(isCountedUniqueTrophy?: boolean): number {
        return Math.floor((100 * this.getAvailableEnsignsCount(isCountedUniqueTrophy)) / this.getTotalEnsignsCount(isCountedUniqueTrophy));
    }

    getTotalDollsCount(isCountedUniqueTrophy?: boolean): number {
        return this.getTotalCountByKey(DOLLS_CATEGORY, isCountedUniqueTrophy);
    }

    getTotalUniqueDollsCount(): number {
        return this.getTotalUniqueCountByKey(DOLLS_CATEGORY);
    }

    getTotalRareDollsCount(): number {
        return this.getTotalRareCountByKey(DOLLS_CATEGORY);
    }

    getAvailableDollsCount(isCountedUniqueTrophy?: boolean): number {
        return this.getAvailableCountByKey(INVENTORY_DOGTAG_KEY, DOLLS_CATEGORY, DOLLS_CATEGORY, isCountedUniqueTrophy);
    }

    getPercentProgressDoll(isCountedUniqueTrophy?: boolean): number {
        return Math.floor((100 * this.getAvailableDollsCount(isCountedUniqueTrophy)) / this.getTotalDollsCount(isCountedUniqueTrophy));
    }

    getPercentForDisplay(percent: number, availableCount: number) {
        let index = 0;
        const steps = [...this.steps];
        const firstStep = steps.shift();

        if (!availableCount) {
            return '10';
        }

        if (availableCount >= firstStep && percent < steps[1]) {
            return '10';
        }

        for (let i = 0; i < this.steps.length; i++) {
            const step = this.steps[i];
            if (this.steps[i + 1] && percent >= step && percent < this.steps[i + 1]) {
                index = i;
                break;
            } else if (percent === step || i === this.steps.length - 1) {
                index = i;
                break;
            }
        }

        return `${++index}0`;
    }

    getTotalCount() {
        return this.getTotalPermoflagesCount() + this.getTotalCrewsCount() + this.getTotalVehiclesCount() + this.getTotalDollsCount() + this.getTotalEnsignsCount();
    }

    getAvailableCount() {
        return this.getAvailablePermoflagesCount() + this.getAvailableCrewCount() + this.getAvailableVehiclesCount() + this.getAvailableDollsCount() + this.getAvailableEnsignsCount();
    }

    getMainProgress() {
        return Math.floor((this.getAvailableCount() * 100) / this.getTotalCount());
    }
}
