import { TYPE_ALL } from '~/helpers/consts';
import { _Item, ICategories } from '~/interfaces';
import { categoryFiltersDependency, facetFiltersDependency, FILTERS_NAMES, IMaps } from '~/Components/Filter/filtersMap';
import { isAnime } from '~/helpers/vehiclesApi';
import { UNIQUE_TYPES } from '~/Actions/ActionFilterTypes';
import { diff } from '~/helpers/array';
import { ICamoFilters, ICrewsFilters, IDollsFilters, IEnsignsFilters, IGlobalFilters, IVehiclesFilters } from '~/Reducers/ReducerFilter';
import { CREWS_CATEGORY, DOLLS_CATEGORY, ENSIGNS_CATEGORY, PERMOFLAGES_CATEGORY, VEHICLE_CATEGORY } from '~/Actions/ActionCategory';

export const LEGENDARY_TYPE = 'LEGENDARY_TYPE';
export const UNIQUE_TYPE = 'UNIQUE_TYPE';
export const COMMON_TYPES = 'COMMON_TYPE';

export const filterItemsByUniqueType = (items: _Item[], uniqueType: UNIQUE_TYPES): _Item[] => {
    return items.filter((item) => {
        switch (uniqueType) {
            case COMMON_TYPES:
                return !item.isUniqueItem && !item.isRareItem;

            case LEGENDARY_TYPE:
                return item.isRareItem;

            case UNIQUE_TYPE:
                return item.isUniqueItem;
        }
    });
};

export const getFiltersStateByCategory = (filtersState: any, category: ICategories) => {
    let filters = filtersState;

    if (category === VEHICLE_CATEGORY) {
        filters = filtersState as IVehiclesFilters;
    } else if (category === CREWS_CATEGORY) {
        filters = filtersState as ICrewsFilters;
    } else if (category === PERMOFLAGES_CATEGORY) {
        filters = filtersState as ICamoFilters;
    } else if (category === ENSIGNS_CATEGORY) {
        filters = filtersState as IEnsignsFilters;
    } else if (category === DOLLS_CATEGORY) {
        filters = filtersState as IDollsFilters;
    }

    return filters;
};

export const filterItems = (filtersState: any, globalFilters: IGlobalFilters, items: _Item[], category: ICategories): _Item[] => {
    const filters = getFiltersStateByCategory(filtersState, category);

    return items.reduce((itemsState: _Item[], item: _Item) => {
        let allowed = true;

        for (const [name, values] of Object.entries<[FILTERS_NAMES, string[]]>(filters)) {
            if (!allowed) {
                break;
            }

            if (values.length) {
                const filterName = name as FILTERS_NAMES;
                const filterValues = values as string[];
                allowed = item.filters[filterName].some((val: string) => {
                    if (name === 'display' && filterValues.includes(TYPE_ALL)) {
                        return true;
                    }

                    return filterValues.includes(val);
                });
            }

            // check anime
            if (!globalFilters.author_content) {
                allowed = allowed && !isAnime(item);
            }
        }

        if (allowed) {
            itemsState.push(item);
        }

        return itemsState;
    }, []);
};

export const getFacetFilters = (filtersState: any, facetState: any, category: ICategories, changedFilterName: FILTERS_NAMES, items: _Item[], filtersMap: IMaps[]) => {
    const facetData: any = {};
    const facetCategoryData = facetState[category] || {};
    const filters = getFiltersStateByCategory(filtersState, category);
    const getFilterMap = (key: FILTERS_NAMES) => filtersMap.filter((_map) => _map.name === key)[0];

    // @ts-ignore
    const filtersDependency = categoryFiltersDependency[category][changedFilterName];
    const selectedFiltersByChangedNameFilters = !filters[changedFilterName].length ? getFilterMap(changedFilterName).defaultValues : filters[changedFilterName];

    items = items.filter((item: _Item) => {
        return selectedFiltersByChangedNameFilters.includes(item.filters[changedFilterName][0]);
    });

    filtersDependency.forEach((depFilterName: FILTERS_NAMES) => {
        const depFilterState = filters[depFilterName];
        if (!facetData[depFilterName]) {
            facetData[depFilterName] = new Set();
        }

        // @ts-ignore
        const facetFilterDepName = facetFiltersDependency[category]?.[changedFilterName]?.[depFilterName];
        if (!facetFilterDepName) {
            return;
        }

        items.forEach((item) => {
            if (!depFilterState?.length || depFilterState?.includes(item.filters[depFilterName][0])) {
                // @ts-ignore
                facetData[depFilterName].add(...item.filters[facetFilterDepName]);
            }
        });
    });

    const disabledFilters: any = {
        [changedFilterName]: [...(facetCategoryData[changedFilterName] || [])],
    };

    filtersDependency.forEach((depFilterName: FILTERS_NAMES) => {
        // @ts-ignore
        const facetFilterDepName = facetFiltersDependency[category]?.[changedFilterName]?.[depFilterName];

        disabledFilters[depFilterName] = [];

        disabledFilters[depFilterName].push(...diff(getFilterMap(depFilterName).defaultValues, Array.from(facetData[facetFilterDepName])));
    });

    return disabledFilters;
};
