import { tpl } from '~/helpers/tpl';

export const query = tpl`
{
    vehicles (lang: "{{ languageCode }}", isCatalogue: true) {
        id
        title
        level
        tags
        nation {
            name
        }
        type {
            name
        }
    }
}
`;
