import { ClanDataResponse } from '~/interfaces';

export const GLOSSARY_URL = window.catalogue.urls.vortex + 'api/graphql/glossary/';
const INVENTORY_URL = window.catalogue.urls.vortex + 'api/graphql/inventory/';
const ACCOUNTS_URL = window.catalogue.urls.vortex + 'api/accounts/';
const LOCAL_GLOSSARY_URL = 'http://vortex.portal.loc:8080/api/graphql/glossary/';
const LOCAL_INVENTORY_URL = 'http://vortex.portal.loc:8080/api/graphql/inventory/';

interface IOptions {
    host?: string;
    query?: string;
}

export const get = (options: IOptions = {}) => {
    let url = options.host || window.catalogue.urls.vortex;
    if (options.query) url = `${url}?query=${encodeURIComponent(options.query)}`;

    return fetch(url, {
        method: 'GET',
    }).then((response) => response.json());
};

export const post = (options: IOptions) => {
    const url = options.host || window.catalogue.urls.vortex;

    return fetch(url, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        credentials: 'include',
        body: JSON.stringify({
            query: options.query,
            variables: null,
            operationName: null,
        }),
    }).then((response) => response.json());
};

export const loadDataFromGlossary = (options: IOptions = {}) => {
    options.host = options.host || GLOSSARY_URL;
    return post(options);
};

export const loadDataFromInventory = (options: IOptions = {}) => {
    options.host = options.host || INVENTORY_URL;
    return post(options);
};

export const loadAccountClanData = (userId: number): Promise<ClanDataResponse> => {
    if (!userId) return null;
    return get({
        host: `${ACCOUNTS_URL}/${userId}/clans/`,
    });
}
