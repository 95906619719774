import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import ContainerProfile from '~/Containers/ContainerProfile';
import ContainerCategory from '~/Containers/ContainerCategory';
import Port from '~/Components/Port/Port';
import { navigate } from '~/helpers/consts';
import { CREWS_CATEGORY, DOLLS_CATEGORY, ENSIGNS_CATEGORY, PERMOFLAGES_CATEGORY, PROFILE_PAGE, VEHICLE_CATEGORY } from '~/Actions/ActionCategory';

const Routing = () => (
    <Switch>
        <Route exact path={navigate[PROFILE_PAGE]} component={ContainerProfile} />
        <Route exact path={navigate[VEHICLE_CATEGORY]} render={(props) => <ContainerCategory category={VEHICLE_CATEGORY} />} />
        <Route exact path={navigate[CREWS_CATEGORY]} render={(props) => <ContainerCategory category={CREWS_CATEGORY} />} />
        <Route exact path={navigate[ENSIGNS_CATEGORY]} render={(props) => <ContainerCategory category={ENSIGNS_CATEGORY} />} />
        <Route exact path={navigate[DOLLS_CATEGORY]} render={(props) => <ContainerCategory category={DOLLS_CATEGORY} />} />
        <Route exact path={navigate[PERMOFLAGES_CATEGORY]} render={(props) => <ContainerCategory category={PERMOFLAGES_CATEGORY} />} />
        <Route exact path={navigate.PORT} render={(props) => <Port />} />
        <Route exact path="*"><Redirect to={navigate[PROFILE_PAGE]} /></Route>
    </Switch>
);

export default Routing;
