import classNames from 'classnames';
import React, { useEffect } from 'react';

import styles from './DogTagClanTag.scss';
import imageData from './symbols/symbolsMediumMap';

interface ClanTagProps {
    className?: string;
    tag?: string;
    color: string;
    x: number;
    y: number;
}

function setWindowSizeToCss() {
    document.body.style.setProperty('--vw', String(window.innerWidth));
}

export default function DogTagClanTag(props: ClanTagProps) {
    const symbols = props.tag ? props.tag.split('') : ['placeholder'];

    // because vw and vh units don't work in scale()
    useEffect(() => {
        setWindowSizeToCss();
        window.addEventListener('resize', setWindowSizeToCss, { passive: true });
        return () => window.removeEventListener('resize', setWindowSizeToCss);
    });

    return (
        <span
            className={classNames(styles.dogTagClanTag, props.className)}
            style={{
                top: `${props.y || 90}%`,
                left: `${props.x || 50}%`,
            }}
        >
      {symbols.map((symbol) => {
          const symbolData = imageData[symbol as keyof typeof imageData];
          if (!symbolData) return null;
          return (
              <span
                  className={styles.symbol}
                  style={{
                      backgroundColor: props.color,
                      width: symbolData.w,
                      height: symbolData.h,
                      // @ts-ignore
                      '-webkit-mask-position': `-${symbolData.x}px -${symbolData.y}px`,
                  }}
              >
            <span
                className={styles.symbolImage}
                style={{
                    backgroundPositionX: 0 - symbolData.x,
                    backgroundPositionY: 0 - symbolData.y,
                }}
            />
          </span>
          );
      })}
    </span>
    );
}
