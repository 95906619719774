import React from 'react';
import { useSelector } from 'react-redux';
import { State } from '~/Reducers';
import { DefaultDogTagComponentsData } from '~/interfaces';
import { Doll, DollIcons } from '~/vortex';
import classnames from 'classnames';
import styles from './DogTagBackground.scss';
import { getLocalPath, isInGameBrowser } from '~/helpers/utils';

interface DogTagBackgroundProps {
    doll: Doll;
    className?: string;
    size?: 'extraLarge';
}

const stateSelector = (state: State): DefaultDogTagComponentsData => {
    return state.ReducerApp.response.defaultDogTagComponents;
};

function getImage(icons: DollIcons, size?: DogTagBackgroundProps['size']) {
    if (size === 'extraLarge') return icons.large;
    return icons.small;
}

export default function DogTagBackground(props: DogTagBackgroundProps) {
    const defaultDogTagComponents = useSelector<State, DefaultDogTagComponentsData>(stateSelector);
    const { doll } = props;

    const isColorizable = doll.isColorizable;
    let backgroundImage = getImage(doll.icons, props.size);
    let borderImage = '';
    const backgroundColor = (isColorizable && defaultDogTagComponents.backgroundColor?.replace('0x', '#')) || '';
    const borderColor = (isColorizable && defaultDogTagComponents.borderColor?.replace('0x', '#')) || '';
    if (isColorizable) {
        const textureData = defaultDogTagComponents.textureData.find((texData) => texData.id == doll.id);
        backgroundImage = getImage(textureData.background, props.size);
        borderImage = getImage(textureData.border, props.size);
    }

    return (
        <div className={classnames(styles.wrapper, props.className, props.size)}>
            {backgroundImage && (
                <div
                    className={classnames(styles.background, styles.component, isColorizable && styles.colorizable)}
                    style={
                        {
                            '--image': `url('${backgroundImage}')`,
                            backgroundColor: backgroundColor,
                        } as React.CSSProperties
                    }
                >
                    <div className={styles.image} />
                </div>
            )}
            {borderImage && (
                <div
                    className={classnames(styles.border, styles.component, isColorizable && styles.colorizable)}
                    style={
                        {
                            '--image': `url('${borderImage}')`,
                            backgroundColor: borderColor,
                        } as React.CSSProperties
                    }
                >
                    <div className={styles.image} />
                </div>
            )}
        </div>
    );
}