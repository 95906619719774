import { navigate } from '~/helpers/consts';
import { t } from '~/helpers/localization';
import { CREWS_CATEGORY, DOLLS_CATEGORY, ENSIGNS_CATEGORY, PERMOFLAGES_CATEGORY, PROFILE_PAGE, VEHICLE_CATEGORY } from '~/Actions/ActionCategory';

export default [
    {
        link: navigate[PROFILE_PAGE],
        title: t('Главная'),
    },
    {
        link: navigate[VEHICLE_CATEGORY],
        title: t('Корабли'),
    },
    {
        link: navigate[CREWS_CATEGORY],
        title: t('Командиры'),
    },
    {
        link: navigate[PERMOFLAGES_CATEGORY],
        title: t('Камуфляжи'),
    },
    {
        link: navigate[ENSIGNS_CATEGORY],
        title: t('Флаги'),
    },
    {
        link: navigate[DOLLS_CATEGORY],
        title: t('Знаки различия'),
    },
];
