import * as React from 'react';
import styles from './AdditionalFilter.scss';
import CheckboxWithLabel from '@wg/wows-react-uikit/CheckboxWithLabel';
import classNames from 'classnames';
import { ICategories } from '~/interfaces';
import globalFiltersMap from '~/Components/AdditionalFilter/globalFiltersMap';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { State } from '~/Reducers';
import { changeGlobalFilters } from '~/Actions/ActionFilter';
import useClickAway from '~/hooks/useClickAway';
import { IGlobalFilters } from '~/Reducers/ReducerFilter';
import { playDropdownClickSound } from '~/helpers/audioApi';

export const TYPE_ALL = 0;
export const TYPE_IS_HAVE = 1;
export const TYPE_IS_NOT_HAVE = 2;

interface IProps {
    category: ICategories;
}

interface IState {
    globalFilters: IGlobalFilters;
}

const stateSelector = (state: State): IState => {
    return {
        globalFilters: state.ReducerFilter.globalFilters,
    };
};

const AdditionalFilter = (props: IProps) => {
    const ref = React.useRef();
    const dispatch = useDispatch();
    const filterGlobalState = useSelector<State, IState>(stateSelector, shallowEqual);

    const [state, setState] = React.useState<{ isVisible: boolean }>({ isVisible: false });

    const renderContent = () => {
        return (
            <div className={styles.filterContainer}>
                <div className={classNames(styles.filterColumn, styles.filterColumnBorder)}>
                    <div className={styles.filterColumnBody}>
                        {globalFiltersMap.map((filterItem) => {
                            const value = filterGlobalState.globalFilters[filterItem.name];
                            return (
                                <div className={styles.filterItem} key={filterItem.name}>
                                    <CheckboxWithLabel
                                        isChecked={value}
                                        onChange={() => {
                                            dispatch(changeGlobalFilters(props.category, filterItem.name, !value));
                                        }}
                                        labelText={filterItem.title}
                                    />
                                </div>
                            );
                        })}
                    </div>
                </div>
            </div>
        );
    };

    const changeVisible = () => {
        setState({
            isVisible: !state.isVisible,
        });
    };

    const classNamesIcon = classNames(styles.filterIcon, {
        [styles.active]: state.isVisible,
    });

    useClickAway(ref, () => {
        setState({ isVisible: false });
    });

    return (
        <div className={styles.filterContent} ref={ref}>
            <div
                className={classNamesIcon}
                onClick={() => {
                    playDropdownClickSound();
                    changeVisible();
                }}
            />
            {state.isVisible && renderContent()}
        </div>
    );
};

export default AdditionalFilter;
