import * as React from 'react';
import AnimatedNumber from 'react-animated-number';

interface IProps {
    value: number;
    duration?: number;
}

const Number = (props: IProps) => {
    return (
        <AnimatedNumber
            style={{
                transition: '0.8s ease-out',
            }}
            value={props.value}
            duration={props.duration || 800}
            formatValue={(n: string) => parseInt(n)}
        />
    );
};

export default Number;
