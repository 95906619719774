import { tpl } from '~/helpers/tpl';

export const query = tpl`
{
    crews (lang: "{{ languageCode }}", isCatalogue: true) {
        id
        title
        type
        tags
        hasSampleVo
        nation {
            name
            title
            icons {
                default
                small
                large
            }
        }
        l10nRarity
    }
}
`;
