import * as React from 'react';
import classNames from 'classnames';
import { NavLink } from 'react-router-dom';
import styles from './Nav.scss';
import NavMap from '~/Components/Nav/NavMap';
import { useDispatch } from 'react-redux';
import { resetSearch } from '~/Actions/ActionCategory';
import { playButtonClickSound } from '~/helpers/audioApi';

const Nav = () => {
    const dispatch = useDispatch();
    const classes = classNames(styles.nav, styles.blurable);
    const onClick = () => {
        dispatch(resetSearch());
        playButtonClickSound();
    };

    return (
        <nav className={classes}>
            {NavMap.map((navItem, index) => {
                return (
                    <NavLink key={index} to={navItem.link} className={styles.link} activeClassName={styles.linkCurrent} onClick={onClick}>
                        {navItem.title}
                    </NavLink>
                );
            })}
        </nav>
    );
};

export default React.memo(Nav);
