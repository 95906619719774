import * as React from 'react';
import * as Sentry from '@sentry/browser';
import ReactDOM from 'react-dom';
import App from './app';

const settings = window.catalogue.settings;
const dsn = settings.sentry ? settings.sentry.dsn : null;
if (dsn !== '') {
    Sentry.init({ dsn: dsn });
}

const mountNode = document.getElementById('app');
if (mountNode) {
    ReactDOM.render(<App />, mountNode);
}
