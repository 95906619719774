import { t } from '~/helpers/localization';
import { playButtonClickSound } from '~/helpers/audioApi';
import styles from './BackButton.scss';
import { goBack } from '~/helpers/router';
import React from 'react';
import classNames from 'classnames';

interface BackButtonProps {
    className?: string;
}

export default function BackButton(props: BackButtonProps) {
    return (
        <div
            className={classNames(styles.back, props.className)}
            onClick={() => {
                playButtonClickSound();
                goBack();
            }}
        >
            {t('назад')}
        </div>
    );
}