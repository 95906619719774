import * as React from 'react';
import styles from './View.scss';
import Nav from '~/Components/Nav/Nav';
import RouteContent from '~/Components/RouteContent/RouteContent';
import DialogManager from '~/Components/Dialog/DialogManager';
import BackButton from '~/Components/BackButton/BackButton';

const View = () => {

    return (
        <>
            <BackButton className={styles.backButton} />
            <div className={styles.app}>
                <DialogManager />
                <Nav />
                <RouteContent />
            </div>
         </>
    );
};

export default View;
