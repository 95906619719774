(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("react"));
	else if(typeof define === 'function' && define.amd)
		define(["react"], factory);
	else if(typeof exports === 'object')
		exports["@wg/wows-react-uikit"] = factory(require("react"));
	else
		root["@wg/wows-react-uikit"] = factory(root["react"]);
})(window, function(__WEBPACK_EXTERNAL_MODULE_cDcdfi41WIMZoCOS__) {
return 